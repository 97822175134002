<template>
  <div class="card list-card latest-message mb-0" v-loading="loading">
    <card-head
      title="最新留言"
      :number="list.length"
      more="/chat"
      @refresh="refresh('message')"
    />
    <el-scrollbar height="calc(50vh - 88px)" style="width: 100%">
      <div
        class="message-item no-select"
        v-for="(item, index) in list"
        :key="'message_' + index"
        @click="openChat(item.id)"
      >
        <div class="item-head">
          <img
            class="avatar"
            alt="男性患者"
            v-if="item.gender == 1"
            src="img/avatar/men.png"
          />
          <img
            class="avatar"
            alt="女性患者"
            v-else
            src="img/avatar/women.png"
          />
          <div>
            <div>{{ item.title }}</div>
            <div class="item-name">
              {{ item.patient }} / {{ item.transplant }}
            </div>
            <div class="item-state">
              <el-tag effect="dark" v-if="item.state === 1">进行中</el-tag>
              <el-tag type="success" effect="dark" v-else>已解决</el-tag>
            </div>
          </div>
        </div>
        <div class="item-info mt-5">
          <el-row :gutter="10">
            <el-col
              :span="24"
              v-for="(talk, i) in item.talk"
              :key="'talk' + index + '_' + i"
            >
              <el-alert
                :type="talk.role == 1 ? 'info' : 'success'"
                :closable="false"
                :description="talk.content"
                style="padding: 5px 5px 5px 0"
              >
                <template v-slot:title
                  >{{ talk.role == 1 ? "患者" : "医生" }}
                  {{ talk.time }}</template
                >
              </el-alert>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import CardHead from "../../components/work/card-head.vue";
import { problem } from "../../plugin/api";
import { date } from "../../plugin/util";

export default {
  name: "MessageCard",
  components: { CardHead },
  data: () => ({
    loading: true,
    list: [],
  }),
  methods: {
    buildDate(obj, name, code) {
      obj[name] = date.format(obj[name], code);
    },
    queryList() {
      this.loading = true;
      problem
        .getNewTalk()
        .then((res) => {
          setTimeout(() => {
            for (let a in res.data) {
              for (let b in res.data[a].talk) {
                this.buildDate(
                  res.data[a].talk[b],
                  "time",
                  "yyyy-mm-dd HH:MM:SS"
                );
              }
            }
            this.loading = false;
            this.list = res.data;
          }, 500);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openChat(id) {
      this.$router.push("/chat/" + id);
    },
  },
};
</script>

<style scoped>
.card {
  height: calc(50vh - 55px);
}

.avatar {
  background-color: #c5c5c5;
  border-radius: 4px;
  margin-right: 5px;
  padding: 2px;
  height: 36px;
  width: 36px;
}

.message-item {
  border-top: 1px solid #ebeef5;
  transition: all ease-out 0.3s;
  background-color: #fff;
  cursor: pointer;
  padding: 10px;
}

.message-item:hover {
  background-color: #fafafa;
}

.item-head {
  align-items: center;
  position: relative;
  display: flex;
}

.item-state {
  position: absolute;
  right: 5px;
  top: 0;
}

.item-date {
  position: absolute;
  font-weight: normal;
  right: 10px;
  top: 5px;
}

.item-name,
.item-state {
  font-size: 14px;
  color: #999;
}

.item-info {
  padding-left: 45px;
}
</style>