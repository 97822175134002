<template>
  <div class="card table-card external-feedback mb-0" v-loading="loading">
    <card-head
      title="外院待反馈"
      :number="list.length"
      more="/external"
      @refresh="queryList"
    />
    <el-table
      stripe
      :data="list"
      height="calc(50vh - 30px)"
      style="width: 100%; border-radius: 8px"
      :row-style="{ cursor: 'pointer' }"
      @row-click="openFollow"
      v-if="list.length > 0"
    >
      <el-table-column prop="name" label="患者姓名" min-width="80" fixed />
      <el-table-column prop="date" label="就诊日期" min-width="110" />
      <el-table-column label="就诊类型" min-width="100">
        <template #default="scope">
          <div v-if="scope.row.type === 1">计划复诊</div>
          <div v-if="scope.row.type === 2">临时复诊</div>
        </template>
      </el-table-column>
      <el-table-column label="接诊信息" min-width="180" class-name="progress">
        <template #default="scope">
          <div style="font-size: 12px; color: #999; line-height: 16px">
            {{ scope.row.department }} · {{ scope.row.doctor }}
          </div>
          <div class="line1">{{ scope.row.hospitalName }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-empty
      description="暂无待反馈"
      v-else
      style="padding-top: calc(50vh - 370px)"
    ></el-empty>
  </div>
</template>

<script>
import CardHead from "../../components/work/card-head.vue";
import { follow } from "../../plugin/api";
import { date } from "../../plugin/util";

export default {
  name: "FeedbackCard",
  components: { CardHead },
  data: () => ({
    loading: true,
    list: [],
  }),
  methods: {
    buildDate(obj, name, code) {
      obj[name] = date.format(obj[name], code);
    },
    queryList() {
      this.loading = true;
      follow
        .getExternalList({ state: 1 }, 1, 50)
        .then((res) => {
          setTimeout(() => {
            for (let i in res.data) {
              this.buildDate(res.data[i], "date", "yyyy-mm-dd");
              this.buildDate(res.data[i], "addTime", "yyyy-mm-dd HH:MM:SS");
            }
            this.loading = false;
            this.list = res.data;
          }, 500);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openFollow(row) {
      this.$router.push(
        // "/patient/" + row.pid + "/follow?id=" + (row.id ? row.id : row)
        "/patient/" + row.pid + "?tab=follow"
      );
    },
  },
};
</script>

<style scoped>
.card {
  height: calc(50vh - 55px);
}
</style>