<template>
  <div class="container" style="overflow-y: hidden">
    <el-row :gutter="10">
      <el-col :span="16">
        <follow-card ref="followCard" />
      </el-col>
      <el-col :span="8">
        <bind-card ref="bindCard" />
      </el-col>
      <el-col :span="12">
        <message-card ref="messageCard" />
      </el-col>
      <el-col :span="12">
        <feedback-card ref="feedbackCard" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import MessageCard from "../../components/work/message-card.vue";
import FollowCard from "../../components/work/follow-card.vue";
import BindCard from "../../components/work/bind-card.vue";
import FeedbackCard from "../../components/work/feedback-card.vue";

export default {
  name: "Work",
  components: { MessageCard, BindCard, FollowCard, FeedbackCard },
  mounted() {
    setTimeout(() => {
      this.$refs.followCard.queryList();
    }, 1000);
    setTimeout(() => {
      this.$refs.messageCard.queryList();
    }, 1300);
    setTimeout(() => {
      this.$refs.bindCard.queryList();
    }, 1500);
    setTimeout(() => {
      this.$refs.feedbackCard.queryList();
    }, 1700);
  },
};
</script>

<style scoped>
</style>
