<template>
  <div class="card-head no-select">
    <div class="head-tools">
      <div class="btn" @click="refresh">
        <el-icon><refresh /></el-icon>
      </div>
      <div class="btn" @click="openMore(more)">
        <span>更多</span>
        <el-icon><arrow-right /></el-icon>
      </div>
    </div>
    <div>
      <span>{{ title }}</span>
      <span class="head-number">{{ number }}</span>
    </div>
  </div>
</template>

<script>
import { ArrowRight, Refresh } from "@element-plus/icons";

export default {
  name: "CardHead",
  components: { ArrowRight, Refresh },
  emits:["refresh"],
  props: {
    title: {
      type: String,
      default: "",
    },
    number: {
      type: Number,
      default: 0,
    },
    more: {
      type: String,
      default: "",
    },
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    openMore(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.btn {
  color: #17c8c2;
}
</style>