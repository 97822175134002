<template>
  <div class="card table-card no-select" v-loading="loading">
    <card-head
      title="近期待随访"
      :number="list.length"
      more="/external"
      @refresh="queryList"
    />
    <el-table
      stripe
      :data="list"
      height="calc(50vh - 30px)"
      style="width: 100%; border-radius: 8px"
      :row-style="{ cursor: 'pointer' }"
      @row-click="openPatient"
      v-if="list.length > 0"
    >
      <el-table-column prop="id" label="编号" width="115" fixed />
      <el-table-column prop="name" label="姓名" min-width="90" fixed />
      <el-table-column prop="surgeryDate" label="手术日期" min-width="120" />
      <el-table-column prop="dischargeDate" label="出院日期" min-width="120" />
      <el-table-column
        prop="nextDate"
        label="随访日期"
        min-width="110"
        sortable
      />
      <el-table-column
        prop="progress"
        align="center"
        label="随访进度"
        min-width="140"
        class-name="progress"
      >
        <template #default="scope">
          <template v-if="scope.row.progress">
            <div class="plan-number">
              计划 {{ scope.row.progress.total }} 次
            </div>
            <el-progress
              style="margin: 2px 0"
              text-inside
              :show-text="false"
              :percentage="
                ((scope.row.progress.total - scope.row.progress.waiting) /
                  scope.row.progress.total) *
                100
              "
              status="success"
            />
            <div class="state-box">
              <span class="state-complete"
                >完成 {{ scope.row.progress.complete }}</span
              >
              ·
              <span class="state-overdue"
                >过期 {{ scope.row.progress.overdue }}</span
              >
            </div>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop="transplantTypeName"
        align="center"
        label="移植类型"
        width="120"
      />
    </el-table>
    <el-empty
      description="暂无近期待随访"
      v-else
      style="padding-top: calc(50vh - 370px)"
    ></el-empty>
  </div>
</template>

<script>
import CardHead from "../../components/work/card-head.vue";
import { patient } from "../../plugin/api";
import { date } from "../../plugin/util";

export default {
  name: "FollowCard",
  components: { CardHead },
  data: () => ({
    loading: true,
    list: [],
  }),
  methods: {
    buildDate(obj, name, code) {
      obj[name] = date.format(obj[name], code);
    },
    queryList() {
      this.loading = true;
      patient
        .getFollowList()
        .then((res) => {
          setTimeout(() => {
            for (let i in res.data) {
              this.buildDate(res.data[i], "nextDate", "yyyy-mm-dd");
              this.buildDate(res.data[i], "dischargeDate", "yyyy-mm-dd");
              this.buildDate(res.data[i], "surgeryDate", "yyyy-mm-dd");
            }
            this.list = res.data;
            this.loading = false;
          }, 500);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openPatient(row) {
      this.$router.push("/patient/" + row.id);
    },
  }
};
</script>

<style scoped>
.card {
  height: 50vh;
}

.progress .plan-number,
.progress .state-box {
  line-height: 14px;
  font-size: 12px;
}

.progress .plan-number {
  color: #999;
}

.progress .state-complete {
  color: #329700;
}

.progress .state-overdue {
  color: #c98317;
}
</style>