<template>
  <div class="card list-card bind-request" v-loading="loading">
    <card-head
      title="绑定申请"
      :number="list.length"
      more="/patient"
      @refresh="queryList"
    />
    <el-scrollbar
      height="calc(50vh - 30px)"
      style="width: 100%"
      v-if="list.length > 0"
    >
      <div
        class="bind-item"
        v-for="(item, index) in list"
        :key="'bind_' + index"
        @click="$router.push('/patient/' + item.id)"
      >
        <div class="item-head">
          <img
            class="avatar"
            alt="男性患者"
            v-if="item.gender == 1"
            src="img/avatar/men.png"
          />
          <img
            class="avatar"
            alt="女性患者"
            v-else
            src="img/avatar/women.png"
          />
          <div>
            <div>{{ item.name }}</div>
            <div class="item-hospital">{{ item.surgeryHospitalName }}</div>
            <div class="item-date">
              {{ buildDate(item.addTime, "yyyy-mm-dd HH:MM:SS") }}
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <el-empty
      description="暂无绑定申请"
      v-else
      style="padding-top: calc(50vh - 370px)"
    ></el-empty>
  </div>
</template>

<script>
import CardHead from "../../components/work/card-head.vue";
import { patient } from "../../plugin/api";
import { date } from "../../plugin/util";

export default {
  name: "BindCard",
  components: { CardHead },
  data: () => ({
    loading: true,
    list: [],
  }),
  methods: {
    queryList() {
      this.loading = true;
      patient
        .getExamineList()
        .then((res) => {
          setTimeout(() => {
            this.loading = false;
            this.list = res.data;
          }, 500);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    buildDate(data, code) {
      return date.format(data, code);
    },
  },
};
</script>

<style scoped>
.card {
  height: 50vh;
}

.avatar {
  background-color: #c5c5c5;
  border-radius: 4px;
  margin-right: 5px;
  padding: 2px;
  height: 36px;
  width: 36px;
}

.bind-item {
  border-top: 1px solid #ebeef5;
  transition: all ease-out 0.3s;
  background-color: #fff;
  cursor: pointer;
  padding: 10px;
}

.bind-item:hover {
  background-color: #f5f7fa;
}

.bind-item .item-head {
  align-items: center;
  position: relative;
  display: flex;
}

.bind-item .item-date {
  position: absolute;
  right: 5px;
  top: 0;
}

.bind-item .item-hospital,
.bind-item .item-date,
.bind-item .item-info {
  font-size: 14px;
  color: #999;
}

.bind-item .item-info .data {
  color: #333;
}

.bind-item .item-info {
  padding-left: 45px;
  line-height: 24px;
}

.bind-item .item-info > div:first-child {
  margin-right: 40px;
}

.item-btn {
  transition: all ease-out 0.3s;
  margin-bottom: -40px;
  position: relative;
  margin-top: 5px;
  height: 32px;
  opacity: 0;
}

.item-btn > button:last-child {
  position: absolute;
  right: 0;
  top: 0;
}

.bind-item:hover .item-btn {
  margin-bottom: 0;
  opacity: 1;
}

.item-btn .el-button + .el-button {
  margin-left: 5px;
}
</style>